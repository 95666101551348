import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useState } from 'react'

import { getEnv } from '../../../utils/getEnv'

export function AdobeTag() {

  const [env, setEnv] = useState<'dev' | 'staging' | 'live' | null>(null)
  const [cookiesAccepted, setCookiesAccepted] = useState<undefined | boolean>(undefined)
  const [check, setCheck] = useState(0)

  const fetchCookies = async () => {
    fetch('/xxx').then((res) => {
      res.json().then((data) => {
        console.log(data)
        console.log(data.OptanonConsent)
        if ('OptanonConsent' in data && !data.OptanonConsent.includes('interactionCount=0')) {
          if (data.OptanonConsent.includes('C0004:1')) {
            setCookiesAccepted(true)
            console.log('cookies')
          } else {
            setCookiesAccepted(false)
            console.log('no cookies')
          }
        }
      })
    })
  }

  useEffect(() => {
    setEnv(getEnv())
    if (cookiesAccepted === undefined) {
      const id = setInterval(() => {
        fetchCookies()
        setCheck(check + 1)
      }, 3000)
      return () => clearInterval(id)
    }
  }, [check, cookiesAccepted])

  if (env === 'staging' && cookiesAccepted) {
    return <Script src="https://assets.adobedtm.com/a2ef59fba8e9/7f6d378ecd1a/launch-bbc1affd862a-staging.min.js" async />
  } else if (env === 'live' && cookiesAccepted) {
    return <Script src="https://assets.adobedtm.com/a2ef59fba8e9/7f6d378ecd1a/launch-2ef5cb9792e7.min.js" async />
  } else if (env === 'dev' && cookiesAccepted) {
    return <Script src="https://assets.adobedtm.com/a2ef59fba8e9/7f6d378ecd1a/launch-bbc1affd862a-staging.min.js" async />
  }

  return null

}
